<template>
  <div
    class="symptoms-conditions-list-container"
    v-if="mappedAnatomyCategories.length"
  >
    <h3>Symptoms & Conditions</h3>

    <div class="anatomy-categories">
      <div
        class="anatomy-category"
        v-for="category in mappedAnatomyCategories"
        :key="category.id"
      >
        <h4 v-if="category.symptomsConditions.length">{{ category.title }}</h4>

        <ul v-if="category.symptomsConditions.length">
          <li
            v-for="symptomCondition in category.symptomsConditions"
            :key="symptomCondition.id"
          >
            <AnchorLink
              class="anchor-link"
              :anchor-link="'/' + symptomCondition.uri"
            >
              {{ symptomCondition.title }}
            </AnchorLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import globals from '@/api/globals'
import helpers from '@/api/helpers/helpers'
import AnchorLink from '@/components/UI/AnchorLink'

export default {
  name: 'SymptomsConditionsList',
  components: { AnchorLink },
  data() {
    return {
      anatomyCategories: [],
      symptomsConditionsData: [],
    }
  },
  computed: {
    mappedAnatomyCategories() {
      if (
        !this.anatomyCategories.length ||
        !this.symptomsConditionsData.length
      ) {
        return []
      }

      let anatomyCategoriesCopy = this.anatomyCategories.slice(0)

      return anatomyCategoriesCopy.map((anatomyCategory) => {
        anatomyCategory.symptomsConditions = this.symptomsConditionsData
          .filter((symptomCondition) => {
            return symptomCondition.anatomyCategory[0].id === anatomyCategory.id
          })
          .sort((a, b) => {
            if (a.title > b.title) return 1

            return b.title > a.title ? -1 : 0
          })
        return anatomyCategory
      })
    },
  },
  mounted() {
    this.loadSymptomsConditionsData()
  },
  methods: {
    loadSymptomsConditionsData() {
      this.loadAnatomyCategories()

      this.setSymptomsConditionsData(
        helpers.readStoredResponse('globals.symptoms-conditions-list')
      )

      globals.loadSymptomsConditionsList().then((response) => {
        this.setSymptomsConditionsData(response)
      })
    },
    setSymptomsConditionsData(response) {
      if (!response || !response.entries) return

      this.symptomsConditionsData = response.entries || []
    },
    loadAnatomyCategories() {
      this.setAnatomyCategories(
        helpers.readStoredResponse('globals.anatomy-categories')
      )

      globals.loadAnatomyCategories().then((response) => {
        this.setAnatomyCategories(response)
      })
    },
    setAnatomyCategories(response) {
      if (!response || !response.categories) return

      this.anatomyCategories = response.categories || []
    },
  },
}
</script>

<style lang="scss" scoped>
.symptoms-conditions-list-container {
  h3 {
    @apply text-white pb-5 mb-5;
    font-family: OswaldLight, serif;
    font-size: 34px;
    border-bottom: 1px solid #4f4f4f;
  }

  .anatomy-categories {
    .anatomy-category {
      h4 {
        @apply text-left;
        font-family: Roboto, sans-serif;
        font-size: 23px;
        font-weight: 300;
        color: #cfcfcf;
      }

      ul {
        @apply block;
        margin: 1em 0 1.25em 1em;
        line-height: 1.6;
        list-style-type: none;

        li {
          @apply text-left;
          font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
          font-weight: 300;

          &::before {
            @apply relative inline-block;
            top: 3px;
            color: #cfcfcf;
            content: '•';
            font-size: 1.3em;
            line-height: 0.8em;
            width: 1em;
            margin-left: -1em;
          }

          .anchor-link {
            text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
            color: #f17800;
            transition: color 0.3s linear;

            &:hover {
              color: #d76b00;
            }
          }
        }
      }
    }
  }
}
</style>
