<template>
  <div class="services-list-container">
    <div
      :class="[{ show: servicesVisible }, 'container']"
      v-observe-visibility="{
        callback: servicesVisibilityChanged,
        once: true,
        intersection: {
          threshold: 0.1,
        },
      }"
    >
      <ServicesListItem
        class="services-list-item"
        v-for="servicesListItem in servicesListData"
        :key="servicesListItem.id"
        :selected="parseInt(servicesListItem.id) === currentServiceId"
        :services-list-item="servicesListItem"
        @loaded="setItemLoaded"
      />
    </div>
  </div>
</template>

<script>
import ServicesListItem from '@/components/Services/ServicesListItem'
export default {
  name: 'ServicesList',
  components: { ServicesListItem },
  props: {
    servicesListData: {
      type: Array,
      required: true,
    },
    currentServiceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      itemsLoaded: [],
      servicesVisible: false,
    }
  },
  methods: {
    servicesVisibilityChanged(isVisible) {
      if (!isVisible) return
      this.servicesVisible = true
    },
    setItemLoaded(id) {
      this.itemsLoaded.push(id)

      if (
        this.servicesListData.length !== 0 &&
        this.itemsLoaded.length === this.servicesListData.length
      ) {
        setTimeout(() => {
          this.$emit('loaded')
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.services-list-container {
  .container {
    @apply flex flex-wrap justify-center;

    &.show {
      .services-list-item {
        @apply opacity-100 left-0 top-0;
        transform: scale(1);
      }
    }

    .services-list-item {
      @apply w-full px-3 mb-5 opacity-0 relative left-10 top-10;
      transform: scale(0.7);
      transition: all 0.3s ease-in;

      @for $i from 1 through 20 {
        &:nth-child(#{$i}n) {
          transition: all 0.5s ease-in #{$i * 0.3}s;
        }
      }
    }
  }

  @media screen and (min-width: 600px) {
    .container {
      .services-list-item {
        @apply w-1/2;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .container {
      .services-list-item {
        @apply w-1/3;
      }
    }
  }
}
</style>
