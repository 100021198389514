<template>
  <div class="content-section-navigation">
    <div class="header">
      <h4>On this page:</h4>
    </div>
    <ul>
      <li v-for="contentSection in contentSections" :key="contentSection.id">
        <AnchorLink
          class="anchor-link"
          :anchor-link="generateAnchorLink(contentSection)"
        >
          {{ contentSection.sectionTitle }}
        </AnchorLink>
      </li>
    </ul>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
import helpers from '@/api/helpers/helpers'
export default {
  name: 'ContentSectionNavigation',
  components: { AnchorLink },
  props: {
    contentSections: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    generateAnchorLink(contentSection) {
      return '#' + helpers.slugify(contentSection.sectionTitle)
    },
  },
}
</script>

<style lang="scss" scoped>
.content-section-navigation {
  @apply text-black;
  border: 1px solid #be5f00;
  margin-bottom: 1.25em;
  padding: 1.25em;
  background: #f17800;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;

  .header {
    border-bottom: 1px solid #4f4f4f;

    h4 {
      font-family: Roboto, sans-serif;
      font-size: 23px;
      font-weight: 300;
      padding-bottom: 10px;
    }
  }

  ul {
    @apply block;
    margin: 1em 0 1.25em 1em;
    line-height: 1.6;
    list-style-type: none;

    li {
      @apply text-left;
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-weight: 300;

      &::before {
        @apply relative inline-block;
        top: 3px;
        color: #cfcfcf;
        content: '•';
        font-size: 1.3em;
        width: 1em;
        margin-left: -1em;
      }

      .anchor-link {
        text-shadow: 0 1px 0 rgba(255, 255, 255, 0.3);
        color: #582c00;
        transition: color 0.3s linear;

        &:hover {
          @apply text-black;
        }
      }
    }
  }
}
</style>
