<template>
  <div class="other-services-container">
    <h2>Our Services</h2>
    <h3 v-if="currentService">
      <span>{{ currentService.title }}</span>
      is one of our core services. We offer a full range of services including:
    </h3>
    <h3 v-else>
      We offer a full range of services to treat symptoms and conditions like
      Medial Collateral Ligament (MCL) Injury.
    </h3>
    <ServicesList
      :services-list-data="servicesListData"
      :current-service-id="currentService ? parseInt(currentService.id) : 0"
    />
  </div>
</template>

<script>
import ServicesList from '@/components/Services/ServicesList'
export default {
  name: 'OtherServicesContainer',

  components: { ServicesList },

  props: {
    currentServiceSlug: {
      type: String,
      required: true,
    },
    servicesListData: {
      type: Array,
      required: true,
    },
  },

  computed: {
    currentService() {
      return this.servicesListData.find(
        (service) => '/' + service.uri === this.currentServiceSlug
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.other-services-container {
  @apply text-white mt-4;

  h2 {
    font-family: FjallaOneRegular, sans-serif;
    font-size: 28px;
  }

  h3 {
    font-family: OswaldLight, serif;
    font-size: 22px;
    margin-top: 0.2em;
    margin-bottom: 0.5em;

    span {
      color: #d76b00;
    }
  }

  @media screen and (min-width: 600px) {
    h2 {
      font-size: 36px;
    }

    h3 {
      font-size: 24px;
    }
  }

  @media screen and (min-width: 900px) {
    h2 {
      font-size: 46px;
    }

    h3 {
      font-size: 34px;
    }
  }
}
</style>
