<template>
  <div class="services-list-item">
    <AnchorLink
      :class="[{ active: selected }, 'anchor-link']"
      :anchor-link="`/${servicesListItem.uri}`"
    >
      <div class="image-container">
        <img
          :src="listTile.backgroundImage[0].url"
          :alt="listTile.backgroundImage[0].title"
        />
      </div>
      <div class="content-container">
        <h4>{{ listTile.titleText }}</h4>
        <p>{{ listTile.shortDescription }}</p>
      </div>
    </AnchorLink>
  </div>
</template>

<script>
import AnchorLink from '@/components/UI/AnchorLink'
export default {
  name: 'ServicesListItem',
  components: { AnchorLink },
  props: {
    servicesListItem: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    listTile() {
      return this.servicesListItem.listTile[0]
    },
  },
  mounted() {
    this.loadImage()
  },
  methods: {
    loadImage() {
      let fakeImage = document.createElement('img')

      fakeImage.onload = () => {
        this.$emit('loaded', this.servicesListItem.id)
      }

      fakeImage.src = this.listTile.backgroundImage[0].url
    },
  },
}
</script>

<style lang="scss" scoped>
.services-list-item {
  @apply w-full overflow-hidden;

  .anchor-link {
    @apply relative block;

    .image-container {
      img {
        @apply w-full;
      }
    }

    .content-container {
      @apply absolute w-full h-full;
      top: calc(100% - 50px);
      background-color: rgba(0, 0, 0, 0.77);
      transition: top 0.5s ease-in;

      h4 {
        color: #f17800;
        font-family: Roboto, sans-serif;
        font-size: 23px;
        font-weight: 300;
        line-height: 2em;
        padding-bottom: 10px;
      }

      p {
        @apply relative p-4 opacity-0;
        left: 50%;
        color: #cfcfcf;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-size: 16px;
        transition: all 0.5s ease-in;
        transition-delay: 0.2s;
      }
    }

    &:hover,
    &.active {
      .content-container {
        @apply top-0;

        p {
          @apply opacity-100 left-0;
        }
      }
    }
  }
}
</style>
